<template>
    <section id="features2">
        <div class="container">
            <div class="section-header d-block d-md-none">
                <h1>{{features.title}}</h1>
            </div>
            <div class="row">
                <div class="col-12 col-md-7">
                    <div class="feature-bg">
                        <img src="assets/images/backgrounds/feature2.png"/>
                    </div>
                </div>
                <div class="col-12 col-md-5">
                    <div class="section-header d-none d-md-block">
                        <h1>{{features.title}}</h1>
                    </div>
                    <div class="section-content">
                        <ul>
                            <li v-for="(section,index) in features.sections" :key="index">
                                <div class="d-flex align-items-center">
                                    <p>{{section.text}}</p>
                                    <span class="enjoy-icon">
                                        <img :src='"/assets/images/icons/"+section.icon+".png"'/>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: ['features'],
}
</script>