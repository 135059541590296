<template>
    <section id="pricing-plans">
        <div class="section-header">
            <h1>{{pricingPlans.title}}</h1>
        </div>
        <div class="section-desc">
            <p>{{pricingPlans.description}}</p>
        </div>
        <div class="section-content d-flex justify-content-center">
            <plan-card :plan='pricingPlans.plans[0]'/>
            <plan-card :plan='pricingPlans.plans[1]'/>
        </div>
    </section>
</template>
<script>
import PlanCard from '@/components/ui/PlanCard'
export default {
    props: ['pricingPlans'],
    components: {
        PlanCard
    },
}
</script>