<template>
    <section id="features3">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-5 order-2 order-md-1">
                    <div class="feature-bg">
                        <img src="assets/images/backgrounds/feature3.png"/>
                    </div>
                </div>
                <div class="col-12 col-md-7 order-1 order-md-2">
                    <div class="section-header">
                        <h1>{{features.title}}</h1>
                    </div>
                    <div class="section-content">
                        <p>{{features.description}}</p>
                    </div>
                </div> 
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: ['features'],
}
</script>
