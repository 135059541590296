<template>
    <section id='popular-reads'>
        <div class="section-header">
            <h1>{{popular_books.title}}</h1>
        </div>
        <div class="books-slider">
            <swiper ref="mySwiper3" :options="swiperOptions" dir="rtl">
                <swiper-slide  v-for="(book,n) in popular_books.books" :key="n">
                    <popular-card :book='book'/>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
            </swiper>
        </div>
    </section>
</template>
<script>
import PopularCard from '@/components/ui/PopularCard'
import { Swiper, SwiperSlide ,directive} from 'vue-awesome-swiper'

// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
export default {
    props: ['popular_books'],
    components: {
        Swiper,
        SwiperSlide,
        PopularCard
    },
    directives: {
        swiper: directive
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper3.$swiper
        }
    },
    data() {
        return {
            
            swiperOptions: {
                slidesPerView: 4,
                spaceBetween: 40,
                pagination: {
                    el: '.swiper-pagination'
                },
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev'
                // },
                breakpoints: {
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 30
                    },
                    1440: {
                        slidesPerView: 5,
                        spaceBetween: 40
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 30
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    },
                    320: {
                        slidesPerView: 2.5,
                        spaceBetween: 15
                    }
                }
                // Some Swiper option/callback...
            },
           
        }
    },
}
</script>