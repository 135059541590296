<template>
    <div class="testimonial-card">
        <div class="testimonial-avatar"> </div>
        <div class="avatar-img">
            <template >
                
               <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M73.4842 36.7421C73.4842 44.009 71.3293 51.1127 67.292 57.1549C63.2548 63.1971 57.5164 67.9064 50.8027 70.6874C44.089 73.4683 36.7013 74.1959 29.5741 72.7782C22.4468 71.3605 15.9 67.8612 10.7615 62.7227C5.62304 57.5842 2.12371 51.0374 0.706004 43.9101C-0.711698 36.7829 0.015925 29.3952 2.79685 22.6815C5.57777 15.9678 10.2871 10.2294 16.3293 6.19216C22.3715 2.15489 29.4752 0 36.7421 0C46.4867 0 55.8322 3.87103 62.7227 10.7615C69.6132 17.652 73.4842 26.9975 73.4842 36.7421Z" fill="#988BC1"/>
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="5" width="69" height="69">
                <path d="M71.4014 39.201C71.4014 45.9651 69.3957 52.5773 65.6377 58.2015C61.8798 63.8256 56.5385 68.2091 50.2892 70.7976C44.04 73.3861 37.1635 74.0634 30.5294 72.7438C23.8952 71.4242 17.8014 68.167 13.0184 63.384C8.23546 58.601 4.97824 52.5072 3.65862 45.8731C2.33901 39.2389 3.01628 32.3624 5.6048 26.1132C8.19331 19.864 12.5768 14.5227 18.201 10.7647C23.8251 7.00677 30.4373 5.00098 37.2015 5.00098C46.2719 5.00098 54.9708 8.60418 61.3845 15.0179C67.7982 21.4317 71.4014 30.1306 71.4014 39.201Z" fill="#60AFBA"/>
                </mask>
                <g mask="url(#mask0)">
                <path d="M56.4011 58.839C54.252 56.1582 37.241 50.2756 37.241 50.2756C37.241 50.2756 20.586 56.7835 18.0785 58.839C15.5711 60.8945 14.6341 73.8887 14.6341 73.8887H59.3553C59.3553 73.8887 58.5491 61.5185 56.4011 58.839Z" fill="#002A50"/>
                <path d="M42.5136 53.4159C42.3408 54.0023 42.0373 54.542 41.6259 54.9943C41.2144 55.4465 40.7058 55.7995 40.1382 56.0268C39.4111 56.3519 38.466 60.4984 37.244 60.4984C35.9539 60.4984 34.9715 56.3263 34.2288 55.9704C33.6895 55.7351 33.2083 55.3844 32.8192 54.9431C32.4301 54.5017 32.1425 53.9804 31.9767 53.4159C32.3541 52.5396 32.5487 51.5955 32.5487 50.6415C32.5487 49.6874 32.3541 48.7434 31.9767 47.8671C30.693 44.8705 44.0845 44.261 42.5136 47.8671C40.9426 51.4732 42.5136 53.4159 42.5136 53.4159Z" fill="#D79563"/>
                <path d="M49.2663 41.4854C50.2475 37.0836 51.4323 30.6024 51.4323 30.6024C55.4897 21.529 51.2816 7.84841 43.4145 12.053C33.6785 3.16103 18.2757 15.5975 23.4854 30.4791C23.8323 34.2226 24.3808 37.9446 25.1285 41.629C26.268 46.5066 34.6749 50.1331 37.2416 50.2756C39.8084 50.4181 48.2292 46.1489 49.2663 41.4854Z" fill="#1D100F"/>
                <path d="M52.1332 33.2C52.1332 35.5772 50.4464 38.8448 49.3117 38.8448C48.1769 38.8448 47.2538 36.9161 47.2538 34.5407C47.2203 33.7099 47.3903 32.8835 47.7489 32.1334C48.1075 31.3833 48.644 30.7322 49.3117 30.2366C50.4115 29.3746 52.1332 30.2349 52.1332 33.2Z" fill="#F9AB78"/>
                <path d="M22.6892 33.2C22.6892 35.5772 24.3759 38.8448 25.5095 38.8448C26.6431 38.8448 27.5685 36.9161 27.5685 34.5407C27.6019 33.7098 27.4317 32.8834 27.0729 32.1333C26.714 31.3832 26.1774 30.7321 25.5095 30.2366C24.4108 29.3746 22.6892 30.2349 22.6892 33.2Z" fill="#F9AB78"/>
                <path d="M37.4585 14.3528C29.4709 14.3528 25.9422 19.9493 25.9422 28.2957V38.7482C25.9422 46.0186 34.3415 52.2363 36.9961 52.2363C39.6507 52.2363 48.9777 44.9659 48.9777 38.0788V28.2957C48.9754 19.9493 45.4449 14.3528 37.4585 14.3528Z" fill="#F9AB78"/>
                <path d="M48.9754 34.2522L49.6949 28.1619C49.6949 19.8149 45.4449 13.5955 37.4585 13.5955C29.4721 13.5955 25.5298 19.8149 25.5298 28.1619L25.9404 35.5911C26.5698 34.3737 26.8673 33.012 26.803 31.643C26.611 26.285 30.3614 25.8069 34.73 24.9723C41.6183 23.6572 43.554 19.5264 43.554 19.5264C43.554 19.5264 45.8113 20.2913 47.2537 23.656C48.1844 25.8319 48.4042 29.1658 48.3321 30.5286C48.3853 31.7923 48.6015 33.0439 48.9754 34.2522Z" fill="#1D100F"/>
                <path d="M30.6772 28.1619C27.587 28.1619 25.6548 29.3107 25.6548 30.5298C25.6548 32.4672 26.362 35.6121 29.4302 35.9832C32.3965 36.3414 36.2742 35.2654 36.2742 30.3151C36.2742 29.167 33.7686 28.1619 30.6772 28.1619ZM29.6512 35.4236C27.0239 35.1066 26.4196 32.4143 26.4196 30.756C26.4196 29.712 28.0732 28.729 30.7191 28.729C33.3649 28.729 35.5106 29.5893 35.5106 30.5711C35.5106 34.8112 32.1941 35.7319 29.6512 35.4236Z" fill="#9A0007"/>
                <path d="M38.2111 31.5703C38.1401 31.3258 37.9835 31.1148 37.77 30.9761C37.5565 30.8373 37.3002 30.7798 37.0478 30.8142C36.3499 30.8142 36.1713 31.6407 36.1713 31.6407L35.8014 29.4613C36.0423 29.6788 36.3355 29.8301 36.6523 29.9004C36.9622 29.9438 37.2766 29.9438 37.5864 29.9004C37.8325 29.8162 38.0497 29.6639 38.2128 29.4613L38.2111 31.5703Z" fill="#9A0007"/>
                <path d="M26.0858 32.7539C26.0858 32.7539 24.9738 32.6742 24.9738 32.1042V30.3873C24.9738 29.4904 26.0858 29.776 26.0858 29.776V32.7539Z" fill="#9A0007"/>
                <path d="M37.7133 30.3151C37.7133 35.2654 41.5875 36.3414 44.5574 35.9832C47.6255 35.6121 48.3322 32.4672 48.3322 30.5298C48.3322 29.3083 46.4005 28.1619 43.3092 28.1619C40.2178 28.1619 37.7133 29.167 37.7133 30.3151ZM38.4781 30.5716C38.4781 29.5898 40.6226 28.7296 43.2685 28.7296C45.9143 28.7296 47.5673 29.7126 47.5673 30.7566C47.5673 32.4148 46.963 35.1072 44.3363 35.4242C41.7952 35.7319 38.4781 34.8112 38.4781 30.5716Z" fill="#9A0007"/>
                <path d="M47.9011 32.7539C47.9011 32.7539 49.0132 32.6742 49.0132 32.1042V30.3873C49.0132 29.4904 47.9011 29.776 47.9011 29.776V32.7539Z" fill="#9A0007"/>
                </g>
                </svg>

            </template>

            <!-- <img :src='review.img' v-if="review.img"/>   -->
        </div>
        <div class="testimonial-rate">
            <star-rating v-model="review.rate" :read-only="true" :show-rating="false"></star-rating>
        </div>
        <div class="testimonial-name">{{review.name}}</div>
        <div class="testimonial-p">{{review.comment}}</div>
        <div class="testimonial-footer">
            <img src='assets/images/icons/test-footer.png'/>
        </div>
    </div>
</template>
<script>

import StarRating from 'vue-star-rating'
export default {
    props: ['review'],
    components: {
        StarRating
    }
}
</script>