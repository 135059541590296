<template>
    <section id='categories'>
        <div class="section-header">
            <h1>{{categories.title}}</h1>
        </div>
        <div class="categories-slider">
            <swiper ref="mySwiper2" :options="swiperOptions" dir="rtl">
                <swiper-slide  v-for="(category,n) in categories.categories" :key="n">
                    <category-card :category='category'/>
                </swiper-slide>
                <div class="swiper-button-prev swiper-button-prev2" slot="button-prev"></div>
                <div class="swiper-button-next swiper-button-next2"  slot="button-next"></div>
                <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
            </swiper>
        </div>
    </section>
</template>
<script>
import CategoryCard from '@/components/ui/CategoryCard'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    props: ['categories'],
    components: {
        Swiper,
        SwiperSlide,
        CategoryCard
    },
    directives: {
        swiper: directive
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper2.$swiper
        }
    },
    data() {
        return {
            swiperOptions: {
                slidesPerView: 4,
                spaceBetween: 40,
                pagination: {
                    el: '.swiper-pagination'
                },
                navigation: {
                    nextEl: '.swiper-button-next2',
                    prevEl: '.swiper-button-prev2'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 7,
                        spaceBetween: 30
                    },
                    1424: {
                        slidesPerView: 7,
                        spaceBetween: 40
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 30
                    },
                    640: {
                        slidesPerView:  2.85,
                        spaceBetween: 15
                    },
                    320: {
                        slidesPerView: 2.85,
                        spaceBetween: 15
                    }
                }
                // Some Swiper option/callback...
            },
        }
    },
   
}
</script>