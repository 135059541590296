<template>
    <section id="features">
        <div class="section-icon">
            <img src="assets/images/icons/f-icon.png"/>
        </div>
        <div class="section-header">
            <h1>{{features.title}}</h1>
        </div>
        <div class="section-desc d-none d-md-block">
            <p>{{features.description1}}</p>
            <p>{{features.description2}}</p>
        </div>
        <div class="section-desc d-block d-md-none">
            <p>{{features.description1}}{{features.description2}}</p>
           
        </div>
        <div class="section-content d-flex justify-content-center">
            <template  v-for="(section,index) in features.sections">
                <feature-card :feature='section' :key="index"/>
            </template>
        </div>
    </section>
</template>
<script>
import FeatureCard from '@/components/ui/FeatureCard'
export default {
    props: ['features'],
    components: {
        FeatureCard
    }
}
</script>