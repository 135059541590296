<template>
	<div class="category-box">
		<div class="category-icon">
			<span>
				<img :src='category.icon'/>
			</span>
		</div>
		<div class="category-title">
			{{category.title}}
		</div>
	</div>
</template>
<script>
export default {
	props: ['category']
}
</script>
<style scoped>

</style>