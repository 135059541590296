<template>
    <div class="card-box">
        <div class="card-icon">
            <img :src='"assets/images/icons/"+feature.icon+".png"'/>
        </div>
        <div class="card-content">
            {{feature.text}}
        </div>
    </div>
</template>
<script>
export default {
    props: ['feature']
}
</script>