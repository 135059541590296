<template>
    <section id="testimonial">
        <div class="section-icon">
            <img src="assets/images/icons/f2-icon.png"/>
        </div>
        <div class="section-header">
            <h1>{{reviews.title}}</h1>
        </div>
        <div class="testimonial-slider">
            <swiper ref="mySwiper1" :options="swiperOptions" dir="rtl">
                <swiper-slide  v-for="(review,n) in reviews.client_reviews" :key="n">
                    <testimonial-card :review='review'/>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
                <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
            </swiper>
        </div>
        <button class="btn-main" @click="handleBtnClick">ابدأ الآن</button>
    </section>
</template>
<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
import TestimonialCard from '@/components/ui/TestimonialCard'
export default {
    props: ['reviews'],
    components: {
        Swiper,
        SwiperSlide,
        TestimonialCard
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOptions: {
                slidesPerView: 4,
                spaceBetween: 40,
                pagination: {
                    el: '.swiper-pagination'
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 30
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 40
                    }
                }
                // Some Swiper option/callback...
            },
           	
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    methods: {
        handleBtnClick() {
			$('html, body').animate({
				scrollTop: $('#pricing-plans').offset().top - $('.header').outerHeight()
			}, 1000);	
		},
    },
    mounted() {
        //this.swiper.slideTo(3, 1000, false)
    }
}
</script>