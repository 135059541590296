<template>
	<div class="popular-card">
		<div class="card-img1">
			<img :src="book.image_large"> 
		</div>
		<div class="card-name">
			{{book.title}}
		</div>
		<div class="card-auth">
			{{book.author}}
		</div>
		<!-- <div class="row">
			<div class="col-auto ml-auto"> -->
<!--				<div class="card-count">-->
<!--					<img src="/assets/images/like.png"> -->
<!--					{{book.likes}}-->
<!--				</div>-->
			<!-- </div>
		</div> -->
	</div>
</template>
<script>
export default {
	props: ['book']
}
</script>
<style lang='scss' >

</style>