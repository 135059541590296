<template>
    <div class="plan-box" :class="{'active':plan.most_purchase}">
        <template v-if="plan.most_purchase">
            <div class="most">
                <svg width="223" height="60" viewBox="0 0 223 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M221.88 51.3069C222.326 51.7883 222.435 52.4804 222.141 53.0522C221.848 53.634 221.217 54.005 220.532 54.005H5.86139C5.86139 54.005 0.176453 54.1856 0.176453 59.3414C0.176453 64.4971 0.176453 11.0237 0.176453 5.59715C0.176453 0.17056 5.86139 0.260836 5.86139 0.260836H220.532C221.217 0.260836 221.848 0.631981 222.141 1.21376C222.435 1.79553 222.326 2.47762 221.88 2.95908L202.184 24.2742C200.662 25.9192 200.662 28.3467 202.184 30.0017L221.88 51.3069Z"
                        fill="white"/>
                </svg>
            </div>
            <span class="most-p">الأكثر شراء</span>
            <div class="discount">
                <img src='assets/images/icons/plan.png'/>
            </div>
        </template>
        <div class="price-info">
            <div class="price-period">{{ plan.text }}</div>
            <div class="price-count">
                <span>{{ plan.text }}/ </span>
                <span class="price">{{ plan.price }}</span>
                <span class="dolar-sign">ر.س </span>
            </div>
        </div>
        <p>
            {{ plan.desc }}
            <!-- <span class="price-count" v-if="plan.desc2"> {{plan.desc2}}</span> -->
        </p>
        <router-link :to="{path:'register-pay', query:{product_id: this.plan.product_id}}">
            <button type="button" class="btn btn-main" @click="openCardPage">ابدأ الآن</button>
        </router-link>
    </div>
</template>
<script>
export default {
    props: ['plan'],
    data() {
        return {}
    },
    methods: {
        openCardPage() {
            this.$store.commit('SET_SELECTED_PLAN', this.plan)
        }
    }
}
</script>