<template>
    <div class="app-store d-flex" :class="className">
        <div class="d-flex flex-row">
            <a 
            class="d-flex flex-row"
            :href="config.ios_url" target="blank">
                <img src='assets/images/app-store/app-black.png'/>
                <div class="app-store-text">
                    Avaliable on <br/> the <span>App Store</span>
                </div>
            </a>
        </div>
        <div class="d-flex flex-row">
            <a 
            class="d-flex flex-row"
            :href="config.android_url" target="blank">
                <img src='assets/images/app-store/logos_google-play-icon.png'/>
                <div class="app-store-text">
                    Android App on<br/> <span>Google Play</span>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        className: String,
        config: {
            type: Object,
            default() {
                return {}
            }
        }
    },
}
</script>
<style lang='scss' scoped>
.app-store {

    & > div {
        width: 173px;
        background: #FFFFFF;
        border: 1px solid #1E2D34;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 7px 10px;
        margin-right: 8px;
        margin-left: 8px;

        img {
            height: 28px;
            margin-right: 7px;
            @media (max-width: 700px) {
                height: 17px;
                margin-right: 5px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1400px) {
                height: 20px;
                margin-right: 5px;
            }
        }

        @media (max-width: 700px) {
            width: 102px;
            padding: 4px 5px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1400px) {
            width: 128px;
            padding: 7px 8px;
            margin-right: 5px;
            margin-left: 5px;
        }
    }

    .app-store-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        color: #000000;

        span {
            font-weight: bold;
        }

        @media (max-width: 700px) {
            font-size: 8.56026px;
            line-height: 13px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1400px) {
            font-size: 11px;
            line-height: 16px;
        }
    }
}
</style>