<template>
    <div>
        <hero :heroInfo='homeInfo.hero' :config='config'/>
        <features :features='homeInfo.features1'/>
        <features2 :features='homeInfo.features2'/>
        <features3 :features='homeInfo.features3'/>
        <testimonial :reviews='homeInfo.reviews'/>
        <categories :categories='homeInfo.categories'/>
        <popular-reads :popular_books='homeInfo.popular_books'/>
        <pricing-plans :pricingPlans='homeInfo.pricingPlans'/>
        <div class="stores-section">
            <app-stores :config="config" :className="'justify-content-center'"/>
        </div>
    </div>
</template>
<script>
import Hero from "./sections/Hero";
import Features from "./sections/Features"
import Features2 from "./sections/Features2"
import Features3 from "./sections/Features3"
import Testimonial from "./sections/Testimonial"
import Categories from "./sections/Categories"
import PopularReads from "./sections/PopularReads"
import PricingPlans from "./sections/PricingPlans"
import AppStores from './sections/AppStores'

export default {
    components: {
        Hero,
        Features,
        Features2,
        Features3,
        Testimonial,
        Categories,
        PopularReads,
        PricingPlans,
        AppStores
    },
    data() {
        return {
            homeInfo: {
                hero: {
                    title: 'مخُتصر',
                    description: 'نقدم لك مُختصرات مجموعة من الكتب المميزة في عالم المال والأعمال والتسويق وتطوير الذات، بالإضافة الى مجموعة من مُختصرات السيرة الذاتية لأبرز الناجحين حول العالم'
                },
                features1: {
                    title: 'مميزات التطبيق',
                    description1: `
                    مع تطبيق مختصر تستطيع زيادة معرفتك وتطوير مهارتك 
                    `,
                    description2: `
                    من خلال الإستفادة من مختصرات الكتب العالمية في أقل من عشرين دقيقة 
                    
                    `,
                    sections: [
                        {icon: 'feature3', text: 'طور من مهارتك'},
                        {icon: 'feature2', text: 'زود من معرفتك'},
                        {icon: 'feature1', text: 'تعلم في وقت قصير'},
                    ]
                },
                features2: {
                    title: `اقرأ أو استمع في أي وقت وأي مكان`,
                    sections: [
                        {icon: 'feature4', text: ' اقرأ بالوضع الليلي '},
                        {icon: 'feature5', text: ' حمل مختصرك المفضل واستمع له بدون انترنت'},
                        {icon: 'feature6', text: 'تحكم في سرعة الصوت المناسبة لك'},
                    ]
                },
                features3: {
                    title: 'ارح عينيك بعيداً عن إضاءة الشاشات',
                    description: 'اقرأ مختصر كتابك المفضل قبل النوم بالوضع الليلي',
                },
                reviews: {
                    title: 'ما يقوله عملاء مختصر المميزون',
                    client_reviews: [
                        {name: 'نجوى محمد', comment: ' لقد احببت هذا التطبيق واعطيته خمس نجوم ', rate: 5},
                        {name: 'نجوى محمد', comment: ' لقد احببت هذا التطبيق واعطيته خمس نجوم ', rate: 5},
                        {name: 'نجوى محمد', comment: ' لقد احببت هذا التطبيق واعطيته خمس نجوم ', rate: 5},
                        {name: 'نجوى محمد', comment: ' لقد احببت هذا التطبيق واعطيته خمس نجوم ', rate: 4},
                        {name: 'نجوى محمد', comment: ' لقد احببت هذا التطبيق واعطيته خمس نجوم ', rate: 5},
                        {name: 'نجوى محمد', comment: ' لقد احببت هذا التطبيق واعطيته خمس نجوم ', rate: 5},
                    ]
                },
                categories: {
                    title: 'مصنفات مختصر',
                    categories: [
                        {icon: 'assets/images/categories/category1.png', title: ' المال'},
                        {icon: 'assets/images/categories/category1.png', title: 'استثمار'},
                        {icon: 'assets/images/categories/category1.png', title: 'التسويق والمبيعات'},
                        {icon: 'assets/images/categories/category1.png', title: 'إدارة الأعمال'},
                        {icon: 'assets/images/categories/category1.png', title: 'القيادة'},
                        {icon: 'assets/images/categories/category1.png', title: 'سيرة شخصية'},
                        {icon: 'assets/images/categories/category1.png', title: ' تسويق'},

                    ]
                },
                popular_books: {
                    title: 'المختصرات الأكثر قراءة',
                    books: [
                        {name: 'المال، إتقان اللعبة', auth: 'David Bach', count: '1,234'},
                        {name: 'الأب الغني والأب الفقير', auth: 'David Bach', count: '1,234'},
                        {name: 'المال، إتقان اللعبة', auth: 'David Bach', count: '1,234'},
                        {name: 'الأب الغني والأب الفقير', auth: 'David Bach', count: '1,234'},
                        {name: 'المال، إتقان اللعبة', auth: 'David Bach', count: '1,234'},
                        {name: 'الأب الغني والأب الفقير', auth: 'David Bach', count: '1,234'},
                        {name: 'المال، إتقان اللعبة', auth: 'David Bach', count: '1,234'},
                        {name: 'الأب الغني والأب الفقير', auth: 'David Bach', count: '1,234'},
                        {name: 'المال، إتقان اللعبة', auth: 'David Bach', count: '1,234'},
                        {name: 'الأب الغني والأب الفقير', auth: 'David Bach', count: '1,234'},
                    ]
                },
                pricingPlans: {
                    title: 'اختر الخطة المناسبة لك',
                    description: `مُختصر متوفر في خطتين شهرية أو سنوية، اختر خطة الدفع المناسبة لك`,
                    plans: [
                        {
                            type: 'YEARLY',
                            text: 'سنويا',
                            price: 224.99,
                            desc: 'تُدفع مرة واحدة في السنة وبإمكانك إلغائها في أي وقت',
                            most_purchase: true,
                        },
                        {
                            type: 'MONTHLY',
                            text: 'شهرياً',
                            price: 34.99,
                            desc: 'تُدفع كل شهر ويمكنك إلغائها في أي وقت',
                            most_purchase: false
                        }
                    ]
                }
            },
            config: {
                ios_url:'',
                android_url: ''
            }
        }
    },
    methods: {
        getData() {
          let homeInfo = this.$store.state.homeInfo;
          if (homeInfo) {
            this.homeInfo.reviews.client_reviews = homeInfo.client_reviews
            this.homeInfo.popular_books.books = homeInfo.popular_books
            this.homeInfo.hero = homeInfo.hero
            this.homeInfo.pricingPlans = homeInfo.pricingPlans
            this.config = homeInfo.config
          }
        }
    },
    mounted() {
        this.getData()
        localStorage.removeItem('couponCode')
        if (this.$route.query) {
            if (this.$route.query.start) {
                $('html, body').animate({
                    scrollTop: $('#pricing-plans').offset().top - $('.header').outerHeight()
                }, 100);
            }
            setTimeout(() => {
                this.$router.replace({}).catch(() => {
                });
                ;
            }, 500);
        }
    }
}
</script>
<style scoped lang='scss'>
.stores-section {
    text-align: center;
    padding-bottom: 60px;
    background-color: #F4F4F4;
    @media (max-width: 700px) {
        padding-bottom: 20px;
    }
}
</style>
