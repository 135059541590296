<template>
    <section class="hero">
        <div class="hero-bg"></div>
        <div class="row">
            <div class="col-12  col-md-7 order-2 order-md-1 video">
                <template v-if="true">
                  <template v-if="heroInfo.video_url">
                    <video controls ref="videoRef"  :src="heroInfo.video_url"></video>
                  </template>
<!--                  <template v-else>-->
<!--                    <img src='assets/images/video.png'>-->
<!--                  </template>-->
<!--                  <button type="button" class="btn btn-main click-btn" @click="playPause()">Click to Play!!</button>-->
                </template>
                <button class="btn-main d-block d-md-none" @click="handleBtnClick">انضم الآن</button>
            </div>
            <div class="col-12 col-md-5 order-1 order-md-2">
                <h1>{{heroInfo.title || title}}</h1>
                <p>{{heroInfo.description || description}}</p>
                <button class="btn-main d-none d-md-block" @click="handleBtnClick">انضم الآن</button>
                <div class="d-none d-md-block">
                   <app-stores :className="'justify-content-end'"/>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import AppStores from './AppStores'
export default {
    props:['heroInfo','config'],
    components: {
        AppStores
    },
    data() {
        return {
            title: 'مخُتصر',
            description: 'نقدم لك مُختصرات مجموعة من الكتب المميزة في عالم المال والأعمال والتسويق وتطوير الذات، بالإضافة الى مجموعة من مُختصرات السيرة الذاتية لأبرز الناجحين حول العالم',
        }
    },
    methods: {
        playPause() { 
            this.$refs.videoRef.play();
            //this.$refs.videoRef.pause(); 
		},
        handleBtnClick() {
			$('html, body').animate({
				scrollTop: $('#pricing-plans').offset().top - $('.header').outerHeight()
			}, 1000);	
		},
    }
}
</script>